.banner {
    min-height: 100%;
    min-height: 95vh;
    display: flex;
    align-items: center;
    background-image: url("../../assets/futbol-hinchada-boca.jpg");
    background-size: cover;
    background-position: center;
    color: #ffffff;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 0 !important;
    border-radius: 0 !important;
}
.banner h1{
    font-size: 3em;
    font-weight: bold;
    padding-top: 2em;
}
.banner h2 {
    margin-bottom: 2em;
}
.banner .button {
    position: relative;
    margin-top: 3vh;
    background-color: #013A6B;
    border: 0.1vw solid #013A6B;
    padding: 1em 4em;
    border-radius: 10px;
}

.banner .button:hover {
    border: 0.1vw solid #ffb300;
    color: #ffb300;
    background-color: #013A6B;
}
.banner .button:focus {
    outline: none;
    background-color: #013A6B;
    box-shadow: none;
  }
.banner .button a {
    text-decoration: none;
    color: white;
    width: 100%;
}
