.us{
    background-color: #013A6B;
    padding: 2vh;
    color: #ffffff;
}

.us h2{
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
}

.us h2::after{
    margin: 20px auto 0px;
    content: "";
    display: block;
    height: 4px;
    width: 10vw;
    background-color: #ffb300;
    margin-top: 2vh;
    margin-bottom: 5vh;
}
.us .usText {
    margin-top: auto;
    margin-bottom: auto;
}
.us p {
    font-size: 1.3em;
    text-align: justify;
}

.smoothContainer {
    position: absolute;
}
.googleMaps {
    position: relative;
    padding-bottom: 75%;
    height: 0;
    height: 400px;
    overflow: hidden;
}
.googleMaps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    height: 400px
}